import { createStatus } from './statusColorSchemes';

const DefaultStatusDetails = {
  'open': createStatus('success', 'Open', ''),
  'active': createStatus('success', 'Active', ''),
  'success': createStatus('success', 'Success', ''),
  'enabled': createStatus('success', 'Enabled', ''),
  'available': createStatus('success', 'Available', ''),
  'hold': createStatus('warning', 'Hold', ''),
  'disabled': createStatus('error', 'Disabled', ''),
  'inactive': createStatus('neutral', 'Inactive', ''),
  'error': createStatus('error', 'Error', ''),
  'addresserror': createStatus('error', 'Address Error', ''),
  'imageerror': createStatus('error', 'Image Error', ''),
  'fulfillmenterror': createStatus('error', 'Fulfillment Error', ''),
  'pendingtimehold': createStatus('warning', 'Pending Time Hold', ''),
  'customercarehold': createStatus('warning', 'Customer Care Hold', ''),
  'reviewhold': createStatus('warning', 'Review Hold', '')
};

export default DefaultStatusDetails;