import React from 'react';
import { Modal, Button } from 'app/components';
import { useDispatch } from 'react-redux';
import { deleteReport } from 'app/store/actions/insight';
import './index.scss';

const DeleteReportModal = ({ reportData, showDeleteReportModal }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteReport({ reportId: reportData.id }));
    showDeleteReportModal(false);
  };

  return (
    <Modal
      className="delete-report-modal"
      title={`Delete Report ${reportData.name ? ` - ${reportData.name}` : ''}`}
      hideButtons={true}
      onClose={() => { showDeleteReportModal(null); }}
    >
      <p>Are you sure you want to delete this report?</p>
      <div className="action-buttons">
        <Button
          variant="secondary"
          size="small"
          label="Cancel"
          onClick={() => { showDeleteReportModal(null); }}
        />
        <Button
          variant="primary"
          size="small"
          label="Delete Report"
          destructive
          onClick={handleDelete}
        />
      </div>
    </Modal>
  );
};

export default DeleteReportModal;
