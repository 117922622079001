import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAllReports } from 'app/store/actions/insight';
import { reportsSelector, reportsLoadingSelector, reportsErrorsSelector, runReportLoadingSelector } from 'app/store/selectors/insight';
import { Card, Button, LoadingAnimation, SearchBar, MessageBar, Pagination } from 'app/components';
import ReportCard from './ReportCard';
import './index.scss';

const ReportsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allReports, setAllReports] = useState(null);

  const reportsData = useSelector(reportsSelector);
  const reportsLoading = useSelector(reportsLoadingSelector);
  const reportsErrors = useSelector(reportsErrorsSelector);
  const runReportLoading = useSelector(runReportLoadingSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '10', 10);
  const searchString = searchParams.get('searchString') || '';

  useEffect(() => {
    dispatch(getAllReports({ page: currentPage, pageSize }));
  }, [currentPage, pageSize, dispatch]);

  useEffect(() => {
    if (reportsData) {
      setAllReports(reportsData.reports);
    }
  }, [reportsData]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    updatedSearchParams.set('page', '1');
    setSearchParams(updatedSearchParams.toString());
  };

  const getFilteredReports = useMemo(() => {
    if (!allReports) return [];
    if (!searchString) return allReports;
    
    return allReports.filter(report => 
      report.name?.toLowerCase().includes(searchString.toLowerCase()) ||
      report.description?.toLowerCase().includes(searchString.toLowerCase())
    );
  }, [allReports, searchString]);

  return (
    <div className="reports-view">
      {(reportsLoading || runReportLoading) && <LoadingAnimation />}
      {(allReports || reportsErrors) && (
        <div className="reports-container">
          <Card>
            <div className="reports-header">
              Reports
              <Button
                variant="primary"
                size="medium"
                label={"Create Report"}
                disabled={reportsErrors ? true : false}
                onClick={() => {
                  navigate(`/admin/reports/create-report`);
                }}
              />
            </div>
            <div className="search-bar-and-filters">
              <SearchBar
                searchPlaceholderText="Search for a Report"
                onSearchStringUpdated={onSearchStringUpdated}
                disabled={reportsErrors ? true : false}
              />                                                                                                                           
            </div>
          </Card>
          {getFilteredReports.length === 0 && !reportsErrors && (
            <Card>
              <MessageBar>
                No reports found. Click the &quot;Create Report&quot; button above to create your first report.
              </MessageBar>
            </Card>
          )}
          {getFilteredReports.map((report) => (
            <ReportCard key={report.id} data={report} />
          ))}
          {reportsErrors && (
            <Card>
              <MessageBar color="yellow">An error occurred while fetching data</MessageBar>
            </Card>
          )}
          {reportsData?.total >= 10 && (
            <Card>
              <div className="reports-per-page-header">
                Reports Per Page
              </div>
              <Pagination
                currentPage={currentPage}
                pageSize={pageSize}
                totalItems={reportsData?.total}
                pageSizes={[5, 10, 20, 50]}
                hideBelow={10}
              />
            </Card>
          )}
        </div>
      )}
    </div>
  );
};

export default ReportsView;