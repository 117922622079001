import {
  getAllQueries,
  getAllQueriesSuccess,
  getAllQueriesFailure,
  runQueryById,
  runQueryByIdSuccess,
  runQueryByIdFailure,
  getAllReports,
  getAllReportsSuccess,
  getAllReportsFailure,
  getReportRunHistory,
  getReportRunHistorySuccess,
  getReportRunHistoryFailure,
  getAvailableReportColumns,
  getAvailableReportColumnsSuccess,
  getAvailableReportColumnsFailure,
  createReport,
  createReportSuccess,
  createReportFailure,
  runReport,
  runReportSuccess,
  runReportFailure,
  deleteReport,
  deleteReportSuccess,
  deleteReportFailure,
  getReportPreviewData,
  getReportPreviewDataSuccess,
  getReportPreviewDataFailure,
  clearReportPreviewData,
  getSpecificReport,
  getSpecificReportSuccess,
  getSpecificReportFailure,
  reset,
} from "app/store/actions/insight"
import { handleActions } from 'redux-actions';

const initialState = {
  // Dashboard state
  queries: {
    loading: false,
    data: null,
    error: null,
  },
  queryData: {
  },

  // Reports state
  reports: { // all reports
    loading: false,
    data: null,
    error: null,
  },
  reportRunHistory: { // run history for all reports
    data: {},
  },
  availableReportColumns: { // available columns for reports
    loading: false,
    data: null,
    error: null,
  },
  createReport: {
    loading: false,
    data: null,
    error: null,
  },
  runReport: {
    loading: false,
    data: null,
    error: null,
  },
  reportPreviewData: {
    loading: false,
    data: null,
    error: null,
  },
}

const insightReducer = handleActions(
  {
    [getAllQueries]: (state) => ({ 
      ...state,
      queries: {
        loading: true,
        data: null,
        error: null,
      },
    }),

    [getAllQueriesSuccess]: (state, data) => ({
      ...state,
      queries: {
        loading: false,
        data: data.payload?.queries,
        error: null,
      },
    }),

    [getAllQueriesFailure]: (state, data) => ({ 
      ...state,
      queries: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [runQueryById]: (state, data) => {
      const { query: { queryId }, dateRange } = data.payload;
    
      const id = `${queryId}-${dateRange || 'global'}`;
    
      // make a deep copy of the queryData in the state using JSON.parse and JSON.stringify
      const queryData = JSON.parse(JSON.stringify(state.queryData));
    
      // initialize the id object if it does not exist
      if (!queryData[id]) {
        queryData[id] = {};
      }
    
      // modify the deep-copied queryData
      queryData[id].loading = true;
      queryData[id].data = null;
      queryData[id].refreshedAt = null;
      queryData[id].error = null;
    
      return { 
        ...state,
        queryData,
      };
    },

    [runQueryByIdSuccess]: (state, data) => {
      const { queryId, dateRange, resp, refreshedAt } = data.payload;
    
      // make a deep copy of the queryData in the state using JSON.parse and JSON.stringify
      const queryData = JSON.parse(JSON.stringify(state.queryData));
      
      // create an id based on the queryId and dateRange
      const id = `${queryId}-${dateRange || 'global'}`;
    
      // modify the deep-copied queryData
      queryData[id].loading = false;
      queryData[id].data = resp.data;
      queryData[id].error = null;
      
      if (refreshedAt) {
        queryData[id].refreshedAt = refreshedAt;
      }
    
      return {
        ...state,
        queryData,
      };
    },
    

    [runQueryByIdFailure]: (state, data) => {
      const { queryId, dateRange, error } = data.payload;
    
      // make a deep copy of the queryData in the state using JSON.parse and JSON.stringify
      const queryData = JSON.parse(JSON.stringify(state.queryData));
      
      // create an id based on the queryId and dateRange
      const id = `${queryId}-${dateRange || 'global'}`;
      
      // modify the deep-copied queryData
      queryData[id].loading = false;
      queryData[id].data = null;
      queryData[id].refreshedAt = null;
      queryData[id].error = error;
      
      return {
        ...state,
        queryData,
      };
    },

    [getAllReports]: (state) => ({
      ...state,
      reports: {
        loading: true,
        data: null,
        error: null,
      },
      reportPreviewData: {
        loading: false,
        data: null,
        error: null,
      }
    }),

    [getAllReportsSuccess]: (state, data) => ({
      ...state,
      reports: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),
    
    [getAllReportsFailure]: (state, data) => ({
      ...state,
      reports: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),
    
    [getReportRunHistory]: (state, { payload: { reportId } }) => ({
      ...state,
      reportRunHistory: {
        data: {
          ...state.reportRunHistory.data,
          [reportId]: {
            ...state.reportRunHistory.data[reportId],
            loading: true,
            error: null,
          }
        }
      },
    }),

    [getReportRunHistorySuccess]: (state, { payload }) => ({
      ...state,
      reportRunHistory: {
        data: {
          ...state.reportRunHistory.data,
          [payload.reportId]: {
            loading: false,
            error: null,
            data: payload.data,
          }
        }
      },
    }),
    [getReportRunHistoryFailure]: (state, { payload: { reportId, error } }) => ({
      ...state,
      reportRunHistory: {
        data: {
          ...state.reportRunHistory.data,
          [reportId]: {
            ...state.reportRunHistory.data[reportId],
            loading: false,
            error,
          }
        }
      },
    }),

    [getAvailableReportColumns]: (state) => ({
      ...state,
      availableReportColumns: {
        ...state.availableReportColumns,
        loading: true,
        error: null,
      },
    }),
    
    [getAvailableReportColumnsSuccess]: (state, data) => ({
      ...state,
      availableReportColumns: {
        ...state.availableReportColumns,
        loading: false,
        data: data.payload,
        error: null,
      },
    }),
    
    [getAvailableReportColumnsFailure]: (state, data) => ({
      ...state,
      availableReportColumns: {
        ...state.availableReportColumns,
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [createReport]: (state) => ({
      ...state,
      createReport: {
        ...state.createReport,
        loading: true,
        data: null,
        error: null,
      },
    }),
    
    [createReportSuccess]: (state, data) => ({
      ...state,
      createReport: {
        ...state.createReport,
        loading: false,
        data: data.payload,
        error: null,
      },
    }),
    
    [createReportFailure]: (state, data) => ({
      ...state,
      createReport: {
        ...state.createReport,
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [runReport]: (state) => ({
      ...state,
      runReport: {
        ...state.runReport,
        loading: true,
        data: null,
        error: null,
      },
    }),
    
    [runReportSuccess]: (state, data) => ({
      ...state,
      runReport: {
        ...state.runReport,
        loading: false,
        data: data.payload,
        error: null,
      },
    }),
    
    [runReportFailure]: (state, data) => ({
      ...state,
      reports: {
        ...state.reports,
        loading: false,
        error: data.payload,
      },
    }),

    [deleteReport]: (state) => ({
      ...state,
      reports: {
        ...state.reports,
        loading: true,
      },
    }),

    [deleteReportSuccess]: (state, { payload: reportId }) => ({
      ...state,
      reports: {
        ...state.reports,
        loading: false,
        data: state.reports.data ? {
          ...state.reports.data,
          reports: state.reports.data.reports?.filter(report => report.id !== reportId) || []
        } : null,
      },
    }),

    [deleteReportFailure]: (state) => ({
      ...state,
      reports: {
        ...state.reports,
        loading: false,
      },
    }),

    [getReportPreviewData]: (state) => ({
      ...state,
      reportPreviewData: {
        ...state.reportPreviewData,
        loading: true,
        error: null,
      },
    }),
    
    [getReportPreviewDataSuccess]: (state, data) => ({
      ...state,
      reportPreviewData: {
        ...state.reportPreviewData,
        loading: false,
        data: data.payload,
        error: null,
      },
    }),
    
    [getReportPreviewDataFailure]: (state, data) => ({
      ...state,
      reportPreviewData: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [clearReportPreviewData]: (state) => ({
      ...state,
      reportPreviewData: {
        loading: false,
        data: null,
        error: null,
      },
    }),

    [getSpecificReport]: (state) => ({
      ...state,
      reports: {
        loading: true,
        data: null,
        error: null,
      },
    }),
    [getSpecificReportSuccess]: (state, { payload }) => ({
      ...state,
      reports: {
        loading: false,
        data: {
          reports: [payload],
        },
        error: null,
      },
    }),
    [getSpecificReportFailure]: (state, { payload }) => ({
      ...state,
      reports: {
        loading: false,
        data: null,
        error: payload,
      },
    }),

    [reset]: () => ({
      ...initialState
    }),
  },
  initialState,
)

export default insightReducer;