import React, { useMemo } from 'react';
import { Tag } from 'app/components';
import StringFilter from './Filters/StringFilter';
import NumericFilter from './Filters/NumericFilter';
import BoolFilter from './Filters/BoolFilter';
import DateFilter from './Filters/DateFilter';
import './index.scss';

const ReportFilters = ({
  activeFilters,
  onFilterChange,
  activeFilter,
  activeFilterColumn,
  onCloseFilter,
}) => {
  const handleRemoveFilter = (columnName) => {
    const newFilters = { ...activeFilters };
    delete newFilters[columnName];
    onFilterChange(newFilters);
  };

  const formatPropertyPath = useMemo(() => (path) => {
    return path.split('.')
      .map(part => part
        .replace(/([A-Z])/g, ' $1') 
        .replace(/^./, str => str.toUpperCase())
        .trim()
      )
      .join(' ► ');
  }, []);

  const renderFilterTags = () => {
    if (Object.keys(activeFilters).length === 0) return null;

    return (
      <div className="filter-tags">
        <span className="applied-filters-label">Applied Filters</span>
        {Object.entries(activeFilters).map(([columnName, filter]) => (
          <Tag
            key={columnName}
            label={`${formatPropertyPath(columnName)} ${filter.value ? `${filter.type}` : `"${filter.type}"`} ${filter.value ? ` "${filter.value}"` : ''}${filter.type === 'between' ? ` and "${filter.value2}"` : ''}`}
            onRemove={() => handleRemoveFilter(columnName)}
          />
        ))}
      </div>
    );
  };

  const renderFilterModal = () => {
    if (!activeFilter || !activeFilterColumn) return null;

    const props = {
      title: `Filter - ${activeFilterColumn.split('.').pop().replace(/([A-Z])/g, ' $1').trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`,
      onClose: onCloseFilter,
      onApply: (filter) => {
        onFilterChange({
          ...activeFilters,
          [activeFilterColumn]: filter
        });
        onCloseFilter();
      },
      onClear: () => {
        handleRemoveFilter(activeFilterColumn);
        onCloseFilter();
      },
      currentFilter: activeFilters[activeFilterColumn]
    };

    switch (activeFilter) {
      case 'String':
        return <StringFilter {...props} />;
      case 'Int':
      case 'Decimal':
        return <NumericFilter {...props} />;
      case 'Bool':
        return <BoolFilter {...props} />;
      case 'Date':
        return <DateFilter {...props} />;
      default:
        return null;
    }
  };

  return (
    <>
      {renderFilterTags()}
      {renderFilterModal()}
    </>
  );
};

export default ReportFilters;
