import { put, takeEvery, call, all, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getAllQueries,
  getAllQueriesSuccess,
  getAllQueriesFailure,
  runQueryById,
  runQueryByIdSuccess,
  runQueryByIdFailure,
  getAllReports,
  getAllReportsSuccess,
  getAllReportsFailure,
  getReportRunHistory,
  getReportRunHistorySuccess,
  getReportRunHistoryFailure,
  getAvailableReportColumns,
  getAvailableReportColumnsSuccess,
  getAvailableReportColumnsFailure,
  createReport,
  createReportSuccess,
  createReportFailure,
  deleteReport,
  deleteReportSuccess,
  deleteReportFailure,
  runReport,
  runReportSuccess,
  runReportFailure,
  pollRunningReport,
  getReportPreviewData,
  getReportPreviewDataSuccess,
  getReportPreviewDataFailure,
  getSpecificReport,
  getSpecificReportSuccess,
  getSpecificReportFailure,
} from "app/store/actions/insight"
import InsightServices from 'app/services/insightServices';

function* fetchAllQueries() {
  try {
    const resp = yield call([InsightServices, InsightServices.getAllQueries]);
    yield put(getAllQueriesSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getAllQueriesFailure(error));
  }
}

function* doRunQueryById(action) {
  const { query: { queryId, runAs, merchantIds, vendorIds }, dateRange } = action.payload;

  try {
    const resp = yield call([InsightServices, InsightServices.runQueryById], queryId, runAs, dateRange, merchantIds, vendorIds);
    const refreshedAt = queryId === 'orders-count-by-status' ? new Date(resp.refreshedAt).toLocaleString() : null;
    yield put(runQueryByIdSuccess({ queryId, dateRange, resp, refreshedAt }));
  } catch (error) {
    yield put(runQueryByIdFailure({ queryId, dateRange, error }));
  }
}

function* fetchAllReports(action) {
  try {
    const resp = yield call([InsightServices, InsightServices.getAllReports], action.payload);
    yield put(getAllReportsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getAllReportsFailure(error));
  }
}

function* fetchReportRunHistory(action) {
  const { reportId, cb, skipPolling, page, pageSize } = action.payload;
  try {
    const resp = yield call([InsightServices, InsightServices.getReportRunHistory], reportId, page, pageSize);
    yield put(getReportRunHistorySuccess({ reportId, data: resp }));

    // Only check for running reports if we're not already polling
    if (!skipPolling) {
      let runId = null;
      resp.runs.forEach(run => {
        if (run.status === 'Running' || run.status === 'Pending') {
          runId = run.id;
        }
      });

      if (runId) {
        // Start polling for report completion
        yield put(pollRunningReport({ reportId, runId, cb }));
      }
    }

    if (cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getReportRunHistoryFailure({ reportId, error }));
  }
}

function* fetchAvailableReportColumns() {
  try {
    const resp = yield call([InsightServices, InsightServices.getAvailableReportColumns]);
    yield put(getAvailableReportColumnsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getAvailableReportColumnsFailure(error));
  }
}

function* doCreateReport(action) {
  const { data, cb } = action.payload;
  try {
    const resp = yield call([InsightServices, InsightServices.createReport], data);
    yield put(createReportSuccess(resp));
    toast.success("Report Created Successfully", {
      theme: 'colored',
    });
    if (cb) cb();
  } catch (error) {
    yield put(createReportFailure(error));
    toast.error("Report Failed To Create", {
      theme: 'colored',
    });
  }
}

function* doDeleteReport(action) {
  const { reportId } = action.payload;
  try {
    yield call([InsightServices, InsightServices.deleteReport], reportId);
    yield put(deleteReportSuccess(reportId));

    
    toast.success('Report deleted successfully', {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(deleteReportFailure(error));
    toast.error('Failed to delete report', {
      theme: 'colored',
    });
  }
}

function* doRunReport(action) {
  const { reportId, runAs, merchantIds, vendorIds, cb, page = 1, pageSize = 3 } = action.payload;
  let runId = null;
  try {
    const resp = yield call([InsightServices, InsightServices.runReport], reportId, runAs, merchantIds, vendorIds);
    runId = resp.id;
    // Update the run history for this report and wait for it to complete
    yield call(fetchReportRunHistory, { 
      payload: { 
        reportId, 
        skipPolling: true,
        page,
        pageSize
      } 
    });
    yield put(runReportSuccess({ reportId, resp }));
  } catch (error) {
    yield put(runReportFailure({ reportId, error }));
    toast.error("Report Failed To Run", {
      theme: 'colored',
    });
    return;
  }

  try {
    // Start polling for report completion
    let isCompleted = false;
    let attemptCount = 0;

    while (!isCompleted) {
      // Keep first 5 attempts at 1 second, then start increasing
      // After 5 attempts, multiply by small factor (1.2) and add attempt count in seconds
      // This gives us roughly: 1s, 1s, 1s, 1s, 1s, 1.8s, 2.9s, 4.3s, 6.2s, 8.6s, 11.8s, etc.
      const delayMs = attemptCount < 5
        ? 1000
        : Math.min(1000 * (Math.pow(1.2, attemptCount - 5) + (attemptCount - 5)), 60000);
      yield delay(delayMs);

      const runDetails = yield call([InsightServices, InsightServices.getReportRunDetails], reportId, runId);
      attemptCount++;

      if (runDetails?.run?.status === 'Completed' || runDetails?.run?.status === 'Failed') {
        isCompleted = true;

        yield call(fetchReportRunHistory, { 
          payload: { 
            reportId, 
            skipPolling: true,
            page,
            pageSize,
            cb: cb ? () => cb(runId) : undefined
          } 
        });
      }
    }
  } catch (error) {
    yield put(runReportFailure({ reportId, error }));
    toast.info("Report completion check failed.  Refresh the page.", {
      theme: 'colored',
    });
    return;
  }
}

function* doPollRunningReport(action) {
  const { reportId, runId, cb } = action.payload;
  try {
    let isCompleted = false;
    let attemptCount = 0;

    while (!isCompleted) {
      const delayMs = attemptCount < 5
        ? 3000
        : Math.min(1000 * (Math.pow(1.2, attemptCount - 5) + (attemptCount - 5)), 60000);
      yield delay(delayMs);

      const runDetails = yield call([InsightServices, InsightServices.getReportRunDetails], reportId, runId);
      attemptCount++;

      if (runDetails?.run?.status === 'Completed' || runDetails?.run?.status === 'Failed') {
        isCompleted = true;

        yield put(getReportRunHistory({
          reportId,
          skipPolling: true,
          cb: cb ? () => cb(runId) : undefined
        }));
      }
    }
  } catch (error) {
    console.error('Polling error:', error);
  }
}

function* fetchReportPreviewData(action) {
  const data = action.payload;
  try {
    const resp = yield call([InsightServices, InsightServices.getReportPreviewData], data);
    yield put(getReportPreviewDataSuccess(resp));
  } catch (error) {
    yield put(getReportPreviewDataFailure(error));
  }
}

function* fetchSpecificReport(action) {
  try {
    const resp = yield call([InsightServices, InsightServices.getSpecificReport], action.payload);
    if (resp?.reports?.length > 0 && resp.reports[0].report) {
      yield put(getSpecificReportSuccess(resp.reports[0].report));
    } else {
      console.error('Unexpected response while fetching specific report:', resp);
      yield put(getSpecificReportFailure(new Error('Unexpected response')));
    }
  } catch (error) {
    console.error('error', error);
    yield put(getSpecificReportFailure(error));
  }
}

function* watchData() {
  yield all([
    takeEvery(getAllQueries, fetchAllQueries),
    takeEvery(runQueryById, doRunQueryById),
    takeEvery(getAllReports, fetchAllReports),
    takeEvery(getReportRunHistory, fetchReportRunHistory),
    takeEvery(getAvailableReportColumns, fetchAvailableReportColumns),
    takeEvery(createReport, doCreateReport),
    takeEvery(runReport, doRunReport),
    takeEvery(deleteReport, doDeleteReport),
    takeEvery(pollRunningReport, doPollRunningReport),
    takeEvery(getReportPreviewData, fetchReportPreviewData),
    takeEvery(getSpecificReport, fetchSpecificReport),
  ]);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}