import { createAction } from 'redux-actions';

const prefix = "INSIGHT";

export const getAllQueries = createAction(`${prefix}/GET_ALL_QUERIES`);
export const getAllQueriesSuccess = createAction(`${prefix}/GET_ALL_QUERIES_SUCCESS`);
export const getAllQueriesFailure = createAction(`${prefix}/GET_ALL_QUERIES_FAILURE`);

export const runQueryById = createAction(`${prefix}/RUN_QUERY_BY_ID`);
export const runQueryByIdSuccess = createAction(`${prefix}/RUN_QUERY_BY_ID_SUCCESS`);
export const runQueryByIdFailure = createAction(`${prefix}/RUN_QUERY_BY_ID_FAILURE`);

export const getAllReports = createAction(`${prefix}/GET_ALL_REPORTS`);
export const getAllReportsSuccess = createAction(`${prefix}/GET_ALL_REPORTS_SUCCESS`);
export const getAllReportsFailure = createAction(`${prefix}/GET_ALL_REPORTS_FAILURE`);

export const getReportRunHistory = createAction(`${prefix}/GET_REPORT_RUN_HISTORY`);
export const getReportRunHistorySuccess = createAction(`${prefix}/GET_REPORT_RUN_HISTORY_SUCCESS`);
export const getReportRunHistoryFailure = createAction(`${prefix}/GET_REPORT_RUN_HISTORY_FAILURE`);

export const getAvailableReportColumns = createAction(`${prefix}/GET_AVAILABLE_REPORT_COLUMNS`);
export const getAvailableReportColumnsSuccess = createAction(`${prefix}/GET_AVAILABLE_REPORT_COLUMNS_SUCCESS`);
export const getAvailableReportColumnsFailure = createAction(`${prefix}/GET_AVAILABLE_REPORT_COLUMNS_FAILURE`);

export const createReport = createAction(`${prefix}/CREATE_REPORT`);
export const createReportSuccess = createAction(`${prefix}/CREATE_REPORT_SUCCESS`);
export const createReportFailure = createAction(`${prefix}/CREATE_REPORT_FAILURE`);

export const runReport = createAction(`${prefix}/RUN_REPORT`);
export const runReportSuccess = createAction(`${prefix}/RUN_REPORT_SUCCESS`);
export const runReportFailure = createAction(`${prefix}/RUN_REPORT_FAILURE`);

export const pollRunningReport = createAction(`${prefix}/POLL_RUNNING_REPORT`);

export const getReportRunDetails = createAction(`${prefix}/GET_REPORT_RUN_DETAILS`);

export const deleteReport = createAction(`${prefix}/DELETE_REPORT`);
export const deleteReportSuccess = createAction(`${prefix}/DELETE_REPORT_SUCCESS`);
export const deleteReportFailure = createAction(`${prefix}/DELETE_REPORT_FAILURE`);

export const getReportPreviewData = createAction(`${prefix}/GET_REPORT_PREVIEW_DATA`);
export const getReportPreviewDataSuccess = createAction(`${prefix}/GET_REPORT_PREVIEW_DATA_SUCCESS`);
export const getReportPreviewDataFailure = createAction(`${prefix}/GET_REPORT_PREVIEW_DATA_FAILURE`);

export const clearReportPreviewData = createAction(`${prefix}/CLEAR_REPORT_PREVIEW_DATA`);

export const getSpecificReport = createAction(`${prefix}/GET_SPECIFIC_REPORT`);
export const getSpecificReportSuccess = createAction(`${prefix}/GET_SPECIFIC_REPORT_SUCCESS`);
export const getSpecificReportFailure = createAction(`${prefix}/GET_SPECIFIC_REPORT_FAILURE`);

export const reset = createAction(`${prefix}/RESET`);