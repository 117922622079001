import React, { useMemo, useEffect, useRef, useState } from 'react';
import { Card, MessageBar } from 'app/components';
import ReactApexChart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';
import { setupChartResizeObserver, getTickAmount } from 'app/pages/Home/Dashboard/utils';

const LateOrdersByFacility = ({ queryData = {} }) => {
  const { data, loading, error } = queryData;

  const countLateShipmentsByFacility = (shipments) => {
    return shipments.reduce((acc, shipment, index) => {
      const facilityName = shipment.facility_name || 'Unknown Facility ' + index;
      if (!acc[facilityName]) {
        acc[facilityName] = 0;
      }
      acc[facilityName]++;
      return acc;
    }, {});
  };

  const lateShipmentsCount = data ? countLateShipmentsByFacility(data) : [];

  // convert the object to an array of [facility, count] pairs, sort by count, and slice to keep only the top 15
  const sortedLateShipmentsCount = Object.entries(lateShipmentsCount)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 15);

  // calculate min and max values with adjustments
  const actualMinValue = Math.min(...sortedLateShipmentsCount.map((item) => item[1]));
  const actualMaxValue = Math.max(...sortedLateShipmentsCount.map((item) => item[1]));

  const minValue = Math.max(0, Math.floor(actualMinValue * 0.98));
  const maxValue = Math.ceil(actualMaxValue * 1.02);

  const chartRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    return setupChartResizeObserver(chartRef, setContainerWidth);
  }, []);

  const tickAmount = useMemo(() => {
    return getTickAmount(sortedLateShipmentsCount, '1', containerWidth);
  }, [sortedLateShipmentsCount, containerWidth]);

  const chartData = useMemo(() => ({
    series: [{
      data: sortedLateShipmentsCount.map(([facility, count]) => ({
        x: facility,
        y: count,
      }))
    }],
    options: {
      title: {
        text: 'Late Orders by Facility',
        align: 'left',
        style: {
          fontFamily: "Source Sans Pro",
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '26px',
          color: '#2E2E2E'
        }
      },
      chart: {
        type: 'bar',
        animations: {
          enabled: false,
        },
        events: {
          click: function (chart, w, e) {
            console.log(chart, w, e)
          }
        }
      },
      colors: ['#9CB4E2', '#F8B05B', '#E8D953', '#6DCB5D', '#9AA4FF'],
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '45%',
          distributed: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: false,
        style: {
          fontSize: '12px',
          colors: ['#ffffff'],
        }
      },
      xaxis: {
        min: minValue,
        max: maxValue,
        tickAmount: tickAmount,
        labels: {
          style: {
            colors: '#878787',
            fontSize: '12px'
          },
          formatter: function (val) {
            return Math.floor(val);
          }
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#878787',
            fontSize: '12px'
          },
        },
      },
      legend: {
        show: false
      },
    },
  }), [sortedLateShipmentsCount, minValue, maxValue, tickAmount]);

  return (
    <Card className={`late-orders-by-facility gooten-dashboard-chart ${data && data.length > 0 ? 'has-data' : ''}`}>
      <div ref={chartRef}>
        {!loading && (
          <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
        )}
        {error && (
          <div className="loading">
            <MessageBar color="yellow">An error occurred while fetching data</MessageBar>
          </div>
        )}
        {loading && (
          <div className="loading">
            <div className="loading-header">
              {chartData.options.title.text}
            </div>
            <Skeleton height={150} count={2} borderRadius={15} />
          </div>
        )}
      </div>
    </Card>
  )
}

export default LateOrdersByFacility;
