import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PageNotFound } from 'app/components';
import ReportsView from './ReportsView';
import ReportDetails from './ReportDetails';


function Reports() {
  return (
    <Routes>
      <Route exact path="/" element={<ReportsView />} />
      <Route path="/create-report/*" element={<ReportDetails />} />
      <Route path="/:reportId/*" element={<ReportDetails />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Reports;