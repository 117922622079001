import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { queriesSelector, queriesLoadingSelector, queriesErrorsSelector, queryDataSelector } from 'app/store/selectors/insight';
import { Card, ButtonMenu, LoadingAnimation, MessageBar } from 'app/components';
import { getAllQueries, runQueryById } from 'app/store/actions/insight';
import { subscriberSelector } from 'app/store/selectors/user';
import { InfoCircle } from 'react-bootstrap-icons';
import OrdersByStatus from './OrdersByStatus';
import LateOrdersInDays from './LateOrdersInDays';
import LateOrdersByFacility from './LateOrdersByFacility';
import AverageFulfillmentTimeByVendor from './AverageFulfillmentTimeByVendor';
import TopProductsFulfilledByCategory from './TopProductsFulfilledByCategory';
import AverageFulfillmentTimeByFacility from './AverageFulfillmentTimeByFacility';
import TotalFulfilledShipmentCountByFacility from './TotalFulfilledShipmentCountByFacility';
import EmptyChart from './EmptyChart';
import './index.scss';
import { getMerchantDetails } from 'app/store/actions/merchant';
import { merchantDetailsSelector } from 'app/store/selectors/merchant';
import TopProductsFulfilledBySku from './TopProductsFulfilledBySku';
import Top5USStateByOrdersCount from './Top5USStateByOrdersCount';
import Top5VendorSKUFulfilled from './Top5VendorSKUFulfilled';
import ShipmentCountByStatus from './ShipmentCountByStatus';

const componentMapping = {
  'orders-count-by-status': [OrdersByStatus],
  'late-order-shipments': [LateOrdersInDays, LateOrdersByFacility],
  'late-order-shipments-count-by-vendor-facility': [LateOrdersByFacility],
  'avg-fulfillment-time-by-vendor': [AverageFulfillmentTimeByVendor],
  'top-5-product-category-fulfilled': [TopProductsFulfilledByCategory],
  'top-5-sku-fulfilled': [TopProductsFulfilledBySku],
  'top-5-vendor-sku-fulfilled': [Top5VendorSKUFulfilled],
  'top-5-us-state-by-orders-count': [Top5USStateByOrdersCount],
  'avg-fulfillment-time-by-vendor-facility': [AverageFulfillmentTimeByFacility],
  'shipments-count-by-status-and-vendor-facility': [TotalFulfilledShipmentCountByFacility],
  'shipments-count-by-status': [ShipmentCountByStatus]
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const allQueries = useSelector(queriesSelector);
  const allQueriesLoading = useSelector(queriesLoadingSelector);
  const allQueriesErrors = useSelector(queriesErrorsSelector);
  const queryData = useSelector(queryDataSelector);
  const subscriber = useSelector(subscriberSelector);
  const [filteredQueries, setFilteredQueries] = useState([]);
  const merchantDetails = useSelector(merchantDetailsSelector);

  const dateRange = parseInt(searchParams.get('dateRange') || '30');

  useEffect(() => {
    dispatch(getAllQueries());
    // for parent merchant, we need to fetch merchant details first...
    if (subscriber?.entity === 'parent-merchant') {
      dispatch(getMerchantDetails(subscriber.id));
    }
  }, []);

  useEffect(() => {
    const shouldRunQueries = allQueries && allQueries.length > 0;
    const needsMerchantDetails = subscriber?.entity === 'parent-merchant';

    if (shouldRunQueries) {
      if (needsMerchantDetails && !merchantDetails) {
        // Wait for merchant details
        return;
      }
      runQueries();
    }
  }, [allQueries, dateRange, merchantDetails]);

  const runQueries = () => {
    const subEntity = subscriber?.entity === 'parent-merchant' ? 'merchant' : subscriber?.entity;
    const mapQueryToRun = (query) => ({
      ...query,
      runAs: subEntity,
      merchantIds: subscriber?.entity === 'merchant' ?
        [subscriber?.id] :
        subscriber?.entity === 'parent-merchant' ?
          merchantDetails?.children.map(child => child.id) :
          null,
      vendorIds: subscriber?.entity === 'vendor' ? [subscriber?.id] : null,
    })

    let queriesToRun =
      allQueries.some(q => q.allowForUserTypes) ?
        allQueries.filter(q => q.allowForUserTypes.includes(subEntity))
          .map(query => mapQueryToRun(query))
        : allQueries.map(query => mapQueryToRun(query));

    setFilteredQueries(queriesToRun);

    queriesToRun.forEach(query => {
      query.queryId = query.id;
      const id = `${query.queryId}-${dateRange}`;
      if (!queryData[id] || !queryData[id].refreshedAt || (new Date() - new Date(queryData[id].refreshedAt)) > 300000) { // 5 minutes
        dispatch(runQueryById({ query, dateRange }));
      }
    });
  }

  const updateDateRange = (val) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('dateRange', val.value);
    setSearchParams(updatedSearchParams.toString());
  }

  const topChartItem =
    filteredQueries.find(query => query.queryId === 'orders-count-by-status') &&
    queryData['orders-count-by-status' + '-' + dateRange]?.data && (
      <OrdersByStatus queryData={queryData['orders-count-by-status' + '-' + dateRange]} />
    );

  const bottomChartItem =
    filteredQueries.find(query => query.queryId === 'shipments-count-by-status') &&
    queryData['shipments-count-by-status' + '-' + dateRange]?.data && (
      <ShipmentCountByStatus queryData={queryData['shipments-count-by-status' + '-' + dateRange]} />
    );

  return (
    <div className="dashboard-view">
      {allQueriesLoading && <LoadingAnimation />}
      <Card className="dashboard-view-header">
        <div className="title-and-date">
          Dashboard
          <ButtonMenu
            label={`Last ${dateRange} Days`}
            variant="secondary"
            size="small"
            options={[
              { value: '7', label: '7 Days' },
              { value: '30', label: '30 Days' },
              { value: '90', label: '90 Days' },
            ].map(val => ({
              value: val.value,
              label: val.label,
              onClick: () => updateDateRange(val)
            }))}
            width={140}
          />
        </div>
        {!allQueriesErrors && (
          <div className="last-updated">
            {queryData['orders-count-by-status' + '-' + dateRange]?.refreshedAt ? (
              <>
                <InfoCircle /> {`Last Updated: ${queryData['orders-count-by-status' + '-' + dateRange].refreshedAt}`}
              </>
            ) : (
              <>
                &nbsp;
              </>
            )}
          </div>
        )}
      </Card>
      {!allQueriesErrors ? (
        <>
          {topChartItem}
          <div className="charts-container">
            {filteredQueries.filter(query => query.queryId !== 'orders-count-by-status' && query.queryId !== 'shipments-count-by-status').map(query => {
              const ComponentArray = componentMapping[query.queryId];
              const id = `${query.queryId}-${dateRange}`;
              return ComponentArray ? ComponentArray.map((Component, idx) => <Component key={`${query.queryId}-${idx}`} queryData={queryData[id]} />) : null;
            })}
          </div>
          {bottomChartItem}
        </>
      ) : (
        <>
          <MessageBar color="yellow">
            An error occurred while loading data
          </MessageBar>
          <div className="charts-container">
            <EmptyChart title="Orders By Status" />
            <EmptyChart title="Late Orders in Days" />
            <EmptyChart title="Average Fulfillment Time by Vendor" />
            <EmptyChart title="Total Fulfilled Shipment Count by Facility" />
          </div>
        </>
      )}
    </div>
  )
}

export default Dashboard;
