import React from 'react';
import moment from 'moment';
import { Button, Table, TableHeader, LoadingAnimation, Card, MessageBar } from 'app/components';
import { Download } from 'react-bootstrap-icons';
import ReportFilters from '../ReportFilters';
import './index.scss';

const PreviewSection = ({
  selectedColumns,
  reportPreviewData,
  reportPreviewDataLoading,
  reportPreviewDataErrorsFound,
  activeFilters,
  onFilterChange,
  activeFilter,
  activeFilterColumn,
  onCloseFilter,
  sortBy,
  sortDir,
  onSort,
  onFilterClick,
  formatTableHeader,
  onDownloadPreview,
  onRetry,
  availableColumns
}) => {
  const formatCellValue = (column, value) => {
    if (value === null || value === undefined) {
      return '';
    }

    const columnType = availableColumns?.columns?.find(col => col.name === column)?.type;

    // Handle boolean values
    if (columnType === 'Bool') {
      return value ? 'True' : 'False';
    }

    // Handle date values
    if (columnType === 'Date') {
      const date = moment(value, moment.ISO_8601, true);
      if (date.isValid()) {
        // Check if time components are all zeros
        return date.hour() === 0 && date.minute() === 0 && date.second() === 0
          ? date.format('YYYY-MM-DD')
          : date.format('YYYY-MM-DD HH:mm:ss');
      }
    }

    return value;
  };

  const renderPreviewTable = () => {
    if (selectedColumns.length === 0) {
      return (
        <div className="no-columns-message">
          Select columns on the left to preview data
        </div>
      );
    }

    if (reportPreviewDataErrorsFound) {
      return (
        <MessageBar color="yellow" className="preview-error-message">
          An error occurred while fetching data{' '}
          <Button
            variant="primary"
            size="tiny"
            label="Retry"
            onClick={onRetry}
          />
        </MessageBar>
      );
    }

    return (
      <>
        <ReportFilters
          activeFilters={activeFilters}
          onFilterChange={onFilterChange}
          activeFilter={activeFilter}
          activeFilterColumn={activeFilterColumn}
          onCloseFilter={onCloseFilter}
        />
        <div className="preview-table">
          {reportPreviewDataLoading && <LoadingAnimation />}

          <div className="table-scroll-container">
            <Table size="medium" highlightRows>
              <TableHeader
                sortBy={sortBy}
                sortDir={sortDir}
                onHeaderClick={onSort}
                onFilterClick={onFilterClick}
                options={selectedColumns.map(column => ({
                  id: column,
                  label: formatTableHeader(column),
                  width: 'auto',
                  orderable: true,
                  align: 'left',
                  filterActive: activeFilters[column] !== undefined
                }))}
              />
              <tbody className="table-body">
                {reportPreviewData?.data?.length === 0 ? (
                  <tr>
                    <td colSpan={selectedColumns.length} className="no-results-message">
                      No results found matching your filter criteria
                    </td>
                  </tr>
                ) : (
                  reportPreviewData?.data?.map((row, index) => (
                    <tr key={index}>
                      {selectedColumns.map(column => (
                        <td key={column}>{formatCellValue(column, row[column])}</td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    );
  };

  return (
    <Card className="data-preview">
      <div className="section-header">
        <div className="section-title">Preview Data</div>
        {selectedColumns.length > 0 && (
          <Button
            icon={<Download />}
            onClick={onDownloadPreview}
            className="download-preview-button"
            variant="secondary"
            size="small"
            label="Download Preview Data"
            disabled={selectedColumns.length === 0 || !reportPreviewData?.data?.length}
          />
        )}
      </div>
      {renderPreviewTable()}
    </Card>
  );
};

export default PreviewSection;
