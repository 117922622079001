import React, { useState, useEffect } from 'react';
import { ButtonIcon, Input } from 'app/components';
import { ArrowLeft, X, PencilSquare, Check } from 'react-bootstrap-icons';
import './index.scss';

const DEFAULT_TITLE = 'Untitled Report';

const TitleSection = ({ currentReport, onNavigateBack, onTitleChange, onEditStateChange }) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(currentReport?.name || DEFAULT_TITLE);

  useEffect(() => {
    if (currentReport?.name) {
      setEditedTitle(currentReport.name);
    }
  }, [currentReport]);

  const handleTitleEdit = (accept) => {
    if (accept) {
      const trimmedTitle = editedTitle.trim();
      if (!trimmedTitle) {
        setEditedTitle(currentReport?.name || DEFAULT_TITLE);
      } else {
        onTitleChange(trimmedTitle);
      }
    } else {
      setEditedTitle(currentReport?.name || DEFAULT_TITLE);
    }
    setIsEditingTitle(false);
    onEditStateChange(false);
  };

  const startEditing = () => {
    setIsEditingTitle(true);
    onEditStateChange(true);
  };

  return (
    <div className="title-section">
      <ButtonIcon 
        icon={<ArrowLeft />} 
        onClick={onNavigateBack}
        className="back-button"
      />
      {isEditingTitle ? (
        <div className="title-edit-container">
          <Input
            type="text"
            name="title"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            className="title-edit-input"
            autoFocus
            showErrorMessages={false}
          />
          <ButtonIcon
            icon={<Check />}
            onClick={() => handleTitleEdit(true)}
            className="title-action-button accept-button"
          />
          <ButtonIcon
            icon={<X />}
            onClick={() => handleTitleEdit(false)}
            className="title-action-button cancel-button"
          />
        </div>
      ) : (
        <div className="title-display">
          <span
            onClick={startEditing}
            className="title-text"
          >
            {currentReport?.name || DEFAULT_TITLE}
          </span>
          <ButtonIcon
            icon={<PencilSquare />}
            onClick={startEditing}
            className="edit-title-button"
          />
        </div>
      )}
    </div>
  );
};

export default TitleSection;
