import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  LoadingAnimation,
} from 'app/components';
import {
  reportsSelector,
  availableReportColumnsSelector,
  availableReportColumnsLoadingSelector,
  createReportLoadingSelector,
  reportPreviewDataSelector,
  reportPreviewDataLoadingSelector,
  reportPreviewDataErrorsSelector
} from 'app/store/selectors/insight';
import {
  getAvailableReportColumns,
  createReport,
  getReportPreviewData,
  clearReportPreviewData,
  getSpecificReport,
} from 'app/store/actions/insight';

import TitleSection from './TitleSection';
import ColumnSelection from './ColumnSelection';
import PreviewSection from './PreviewSection';
import { buildFiltersObject } from './filterUtils';
import './index.scss';

// Constants
const DEFAULT_TITLE = 'Untitled Report';

const ReportDetails = () => {
  const { reportId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Selectors
  const reportsData = useSelector(reportsSelector);
  const availableColumns = useSelector(availableReportColumnsSelector);
  const columnsLoading = useSelector(availableReportColumnsLoadingSelector);
  const createReportLoading = useSelector(createReportLoadingSelector);
  const reportPreviewData = useSelector(reportPreviewDataSelector);
  const reportPreviewDataLoading = useSelector(reportPreviewDataLoadingSelector);
  const reportPreviewDataErrorsFound = useSelector(reportPreviewDataErrorsSelector);

  // State
  const [currentReport, setCurrentReport] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortDir, setSortDir] = useState('asc');
  const [editedTitle, setEditedTitle] = useState('');
  const [isManualEdit, setIsManualEdit] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [activeFilter, setActiveFilter] = useState(null);
  const [activeFilterColumn, setActiveFilterColumn] = useState(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  // Effects
  useEffect(() => {
    if (!availableColumns && !columnsLoading) {
      dispatch(getAvailableReportColumns());
    }
  }, [availableColumns, columnsLoading]);

  useEffect(() => {
    if (!reportsData && reportId && availableColumns) {
      dispatch(getSpecificReport(reportId));
    }
  }, [reportsData, reportId, availableColumns]);

  useEffect(() => {
    if (reportsData?.reports && reportId) {
      const matchingReport = reportsData.reports.find(report => report.id === reportId);
      setCurrentReport(matchingReport || null);
      setEditedTitle(matchingReport?.name || DEFAULT_TITLE);
      if (matchingReport?.columns) {
        setSelectedColumns(matchingReport.columns);
      }
    }
  }, [reportsData, reportId]);

  const fetchPreviewData = useCallback((columns) => {
    if (columns.length > 0) {
      const previewData = {
        columns: columns,
        runAs: 'admin',
        merchantIds: [],
        vendorIds: [],
        limit: 20,
        filter: buildFiltersObject(activeFilters)
      };
      dispatch(getReportPreviewData(previewData));
    }
  }, [dispatch, activeFilters]);

  const handleRetryPreview = useCallback(() => {
    if (selectedColumns.length > 0) {
      const filter = buildFiltersObject(activeFilters);
      dispatch(getReportPreviewData({ columns: selectedColumns, filter, sortBy, sortDir }));
    }
  }, [dispatch, selectedColumns, activeFilters, sortBy, sortDir]);

  // Effect for handling column changes
  useEffect(() => {
    if (!selectedColumns.length) {
      dispatch(clearReportPreviewData());
      return;
    }

    if (isManualEdit) {
      const timer = setTimeout(() => {
        fetchPreviewData(selectedColumns);
        setIsManualEdit(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [selectedColumns, isManualEdit]);

  // Only fetch preview data when filters change AND it's not a column removal operation
  useEffect(() => {
    if (selectedColumns.length && activeFilters && !isManualEdit) {
      fetchPreviewData(selectedColumns);
    }
  }, [activeFilters]);

  const formatTableHeader = (name) => {
    const sections = name.split('.');
    const processedSections = sections.map(section => {
      const words = section.replace(/([A-Z])/g, ' $1').trim().split(' ');
      return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    });
  
    const lastWord = processedSections.pop();
    const previousWords = processedSections.length > 0 ? processedSections.join(' • ') : '';
  
    return (
      <>
        <div className="header-first-word">{lastWord}</div>
        {previousWords && <div className="header-remaining">{previousWords}</div>}
      </>
    );
  };

  // Memoized functions
  const buildColumnTree = useMemo(() => {
    if (!availableColumns?.columns) return {};

    const tree = { name: 'Root', children: {} };
    const searchLower = searchString.toLowerCase();

    availableColumns.columns.forEach(column => {
      if (searchString && !column.name.toLowerCase().includes(searchLower)) {
        return;
      }

      const parts = column.name.split('.');
      let currentNode = tree;

      parts.forEach((part, index) => {
        if (index === parts.length - 1) {
          if (!currentNode.columns) {
            currentNode.columns = [];
          }
          currentNode.columns.push({
            name: part,
            fullName: column.name,
            allowForUserTypes: column.allowForUserTypes
          });
        } else {
          if (!currentNode.children) {
            currentNode.children = {};
          }
          if (!currentNode.children[part]) {
            currentNode.children[part] = {
              name: part,
              children: {},
              isExpandable: true
            };
          }
          currentNode = currentNode.children[part];
        }
      });
    });

    const cleanupEmptyNodes = (node) => {
      if (!node) return false;

      if (node.children) {
        Object.keys(node.children).forEach(key => {
          if (!cleanupEmptyNodes(node.children[key])) {
            delete node.children[key];
          }
        });
      }

      return (node.columns?.length > 0) ||
        (node.children && Object.keys(node.children).length > 0);
    };

    cleanupEmptyNodes(tree);
    return tree;
  }, [availableColumns, searchString]);

  const hasResults = reportPreviewData?.data?.length > 0;

  // If we're trying to load a specific report but don't have the data yet, show loading
  if (reportId && !reportsData) {
    return <LoadingAnimation />;
  }

  // Handlers
  const handleColumnSelect = (columnNames, isSelected) => {
    setIsManualEdit(true);
    setSelectedColumns(prev => {
      if (isSelected) {
        return [...new Set([...prev, ...columnNames])];
      }
      const newColumns = prev.filter(col => !columnNames.includes(col));
      setActiveFilters(prevFilters => {
        const newFilters = { ...prevFilters };
        columnNames.forEach(col => {
          if (newFilters[col]) {
            delete newFilters[col];
          }
        });
        return newFilters;
      });
      return newColumns;
    });
  };

  const handleClearColumns = () => {
    setIsManualEdit(true);
    setSelectedColumns([]);
    setActiveFilters({});
    dispatch(clearReportPreviewData());
  };

  const handleSort = (headerName) => {
    setSortBy(headerName);
    setSortDir(prev => prev === 'asc' ? 'desc' : 'asc');
  };

  const handleFilterClick = (columnName) => {
    const columnType = availableColumns.columns.find(col => col.name === columnName)?.type;
    setActiveFilterColumn(columnName);
    setActiveFilter(columnType);
  };

  const handleFilterChange = (newFilters) => {
    setActiveFilters(newFilters);
  };

  const handleDownloadPreview = () => {
    if (!reportPreviewData?.data) return;

    const escapeCsvValue = (value) => {
      if (value == null) return '';
      const stringValue = String(value);
      if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
        return `"${stringValue.replace(/"/g, '""')}"`;
      }
      return stringValue;
    };

    const csvContent = [
      selectedColumns,
      ...reportPreviewData.data.map(row =>
        selectedColumns.map(column => escapeCsvValue(row[column]))
      )
    ];

    const csvString = csvContent.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    const fileName = (currentReport?.name || DEFAULT_TITLE)
      .replace(/[^a-z0-9]/gi, '_')
      .toLowerCase();
    link.download = `${fileName}_preview.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCreateReport = () => {
    if (selectedColumns.length === 0) return;

    const reportData = {
      name: editedTitle || DEFAULT_TITLE,
      columns: selectedColumns,
      merchantIds: [],
      vendorIds: [],
      allowForUserTypes: ['admin'],
      filter: buildFiltersObject(activeFilters)
    };

    dispatch(createReport({ 
      data: reportData, 
      cb: () => {
        navigate('/admin/reports');
      } 
    }));
  };

  const handleTitleChange = (newTitle) => {
    setEditedTitle(newTitle);
    setCurrentReport(prev => ({ ...prev, name: newTitle }));
  };

  const handleTitleEditStateChange = (editing) => {
    setIsEditingTitle(editing);
  };

  return (
    <div className="report-details">
      {createReportLoading && <LoadingAnimation />}

      <Card className="report-details-header">
        <TitleSection
          currentReport={currentReport}
          onNavigateBack={() => navigate('/admin/reports')}
          onTitleChange={handleTitleChange}
          onEditStateChange={handleTitleEditStateChange}
        />
        <div className="action-buttons">
          <Button
            variant="primary"
            label="Create Report"
            onClick={handleCreateReport}
            loading={createReportLoading}
            disabled={!hasResults || createReportLoading || isEditingTitle}
          />
        </div>
      </Card>

      <div className="cards-container">
        <ColumnSelection
          selectedColumns={selectedColumns}
          onColumnSelect={handleColumnSelect}
          onClearColumns={handleClearColumns}
          searchString={searchString}
          onSearchStringUpdated={setSearchString}
          columnTree={buildColumnTree}
        />

        <PreviewSection
          selectedColumns={selectedColumns}
          reportPreviewData={reportPreviewData}
          reportPreviewDataLoading={reportPreviewDataLoading}
          reportPreviewDataErrorsFound={reportPreviewDataErrorsFound}
          activeFilters={activeFilters}
          onFilterChange={handleFilterChange}
          activeFilter={activeFilter}
          activeFilterColumn={activeFilterColumn}
          onCloseFilter={() => {
            setActiveFilter(null);
            setActiveFilterColumn(null);
          }}
          sortBy={sortBy}
          sortDir={sortDir}
          onSort={handleSort}
          onFilterClick={handleFilterClick}
          formatTableHeader={formatTableHeader}
          onDownloadPreview={handleDownloadPreview}
          onRetry={handleRetryPreview}
          availableColumns={availableColumns}
        />
      </div>
    </div>
  );
};

export default ReportDetails;