import React from 'react';
import { Formik } from 'formik';
import { object, string, date, ref } from 'yup';
import { Modal, Button, Dropdown } from 'app/components';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './index.scss';

const DateFilter = (props) => {
  const { title, onClose, onApply, onClear, currentFilter } = props;

  const getInitialValues = () => {
    const parseDate = (dateStr) => {
      if (!dateStr) return null;
      const [month, day, year] = dateStr.split('/');
      return new Date(year, month - 1, day);
    };

    return {
      condition: currentFilter?.type || '',
      date1: currentFilter ? parseDate(currentFilter.value) : null,
      date2: currentFilter ? parseDate(currentFilter.value2) : null,
    };
  };

  const validationSchema = object().shape({
    condition: string().required('Condition is required'),
    date1: date().nullable().required('Start date is required'),
    date2: date()
      .nullable()
      .when('condition', {
        is: 'between',
        then: (schema) => schema.required('End date is required')
          .min(ref('date1'), 'End date must be after start date'),
      }),
  });

  const formatFilterValue = (values) => {
    const formatDate = (date) => {
      if (!date) return null;
      const d = new Date(date);
      return `${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getDate().toString().padStart(2, '0')}/${d.getFullYear()}`;
    };

    return {
      columnType: 'date',
      type: values.condition,
      value: formatDate(values.date1),
      ...(values.condition === 'between' && { value2: formatDate(values.date2) }),
    };
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onApply(formatFilterValue(values));
      }}
    >
      {({ values, errors, handleChange, setFieldValue, submitCount, handleSubmit }) => (
        <Modal
          className="date-filter"
          title={title}
          hideButtons={true}
          onClose={onClose}
        >
          <div className="fixed-date-filter">
            <Dropdown
              label="Condition"
              name="condition"
              value={values.condition}
              onChange={(e) => {
                handleChange(e);
                setFieldValue('condition', e.target.value);
                setFieldValue('date1', null);
                setFieldValue('date2', null);
              }}
              options={[
                { value: 'on', label: 'On' },
                { value: 'onOrBefore', label: 'On or Before' },
                { value: 'onOrAfter', label: 'On or After' },
                { value: 'before', label: 'Before' },
                { value: 'after', label: 'After' },
                { value: 'between', label: 'Between' },
              ]}
              errorMessage={submitCount > 0 && errors.condition}
            />
            {values.condition && (
              <div className="date-picker-container">
                <div className="date-picker-wrapper">
                  <label>{values.condition === 'between' ? 'From' : 'Date'}</label>
                  <DatePicker
                    onChange={(date) => setFieldValue('date1', date)}
                    value={values.date1}
                    format="MM/dd/yyyy"
                    clearIcon={null}
                  />
                  {submitCount > 0 && errors.date1 && (
                    <div className="error-message">{errors.date1}</div>
                  )}
                </div>
                {values.condition === 'between' && (
                  <div className="date-picker-wrapper">
                    <label>To</label>
                    <DatePicker
                      onChange={(date) => setFieldValue('date2', date)}
                      value={values.date2}
                      format="MM/dd/yyyy"
                      clearIcon={null}
                      minDate={values.date1}
                    />
                    {submitCount > 0 && errors.date2 && (
                      <div className="error-message">{errors.date2}</div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="action-buttons">
            <Button
              variant="secondary"
              size="small"
              label="Cancel"
              onClick={onClose}
            />
            <Button
              variant="secondary"
              size="small"
              label="Clear Filter"
              onClick={onClear}
            />
            <Button
              variant="primary"
              size="small"
              label="Apply Filter"
              onClick={handleSubmit}
              disabled={!values.condition}
            />
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default DateFilter;
