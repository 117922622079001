import React, { useEffect, useState, useMemo } from 'react';
import { Card, Button, ButtonIcon, Table, TableHeader, StatusChip, LoadingAnimation, Pagination } from 'app/components';
import { ThreeDotsVertical, ChevronDown, ChevronLeft, ExclamationCircle, Download } from 'react-bootstrap-icons';
import { getReportRunHistory, runReport } from 'app/store/actions/insight';
import { reportRunHistoryByIdSelector, runReportLoadingSelector } from 'app/store/selectors/insight';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LoadingIcon } from "app/assets/images/icons/loading.svg";
import { toast } from 'react-toastify';
import DeleteReportModal from './DeleteReportModal';
import moment from 'moment';
import './index.scss';

const ReportCard = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reportHistory = useSelector(reportRunHistoryByIdSelector(data.id));
  const runReportLoading = useSelector(runReportLoadingSelector);

  const [sortBy, setSortBy] = useState('dateRun');
  const [sortDir, setSortDir] = useState('asc');
  const [isOpen, setIsOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const [highlightedRunId, setHighlightedRunId] = useState(null);
  const [deleteReportModal, showDeleteReportModal] = useState(false);
  const [downloadingRunIds, setDownloadingRunIds] = useState([]);

  const isReportRunning = useMemo(() => {
    return reportHistory?.data?.runs?.some(run => run.status === 'Running');
  }, [reportHistory?.data?.runs]);

  const handleSort = (headerName) => {
    setSortBy(headerName);
    setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
  };

  const handleRunIdCallback = (runId) => {
    if (runId) {
      setHighlightedRunId(runId);
      // Remove the highlight after animation completes
      setTimeout(() => setHighlightedRunId(null), 1500);
    }
  };

  useEffect(() => {
    dispatch(getReportRunHistory({
      reportId: data.id,
      page: currentPage,
      pageSize: pageSize,
      cb: handleRunIdCallback
    }));
  }, [currentPage, pageSize]);

  const handleRunReport = () => {
    dispatch(runReport({
      reportId: data.id,
      runAs: 'Admin',
      merchantIds: [],
      vendorIds: [],
      page: currentPage,
      pageSize: pageSize,
      cb: handleRunIdCallback
    }));
  };

  // Use the paginated data directly from the API response
  const displayedItems = useMemo(() => {
    if (!reportHistory?.data?.runs) return [];
    return reportHistory.data.runs;
  }, [reportHistory?.data?.runs]);

  const totalItems = useMemo(() =>
    reportHistory?.data?.total || 0,
    [reportHistory?.data?.total]
  );

  const reportOptions = () => [
    {
      label: 'Edit Report',
      value: 'editReport',
      onClick: () => {
        navigate(`/admin/reports/${data.id}`);
      }
    },
    {
      label: 'Delete Report',
      value: 'deleteReport',
      destructive: true,
      onClick: () => {
        showDeleteReportModal(true);
      }
    }
  ]

  const handleDownload = async (downloadUrl, name, runId) => {
    try {
      setDownloadingRunIds(prev => [...prev, runId]);
      const response = await fetch(downloadUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      // Create anchor element
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${name || 'report'}.csv`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Cleanup blob URL
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      toast.error("Error downloading file", { theme: 'colored' });
    } finally {
      setDownloadingRunIds(prev => prev.filter(id => id !== runId));
    }
  };

  return (
    <Card className="report-card">
      <div className="report-header">
        <div className="left-side">
          <div className="report-title" onClick={() => navigate(`/admin/reports/${data.id}`)}>
            {data.name || 'Unnamed Report'}
          </div>
          {isOpen && (
            <div className="report-metadata">
              <div className="metadata-row">
                <div className="metadata-label">Created By:</div>
                <div className="metadata-value">{data.createdBy || '-'}</div>
              </div>
              <div className="metadata-row">
                <div className="metadata-label">Created Date:</div>
                <div className="metadata-value">{data.createdAt ? moment(data.createdAt).local().format('MMM DD, YYYY h:mm:ss a') : '-'}</div>
              </div>
            </div>
          )}
        </div>
        <div className="right-side">
          <Button
            variant="secondary"
            onClick={handleRunReport}
            disabled={isReportRunning || reportHistory?.loading || runReportLoading}
            label={isReportRunning ? 'Running...' : 'Run Report'}
          />
          <ButtonIcon
            icon={<ThreeDotsVertical />}
            options={reportOptions()}
          />
          <ButtonIcon
            icon={isOpen ? <ChevronDown /> : <ChevronLeft />}
            onClick={() => setIsOpen(!isOpen)}
            className={!isOpen ? 'collapsed' : ''}
          />
        </div>
      </div>
      {isOpen && (
        <Table size="medium">
          <TableHeader
            sortBy={sortBy}
            sortDir={sortDir}
            onHeaderClick={handleSort}
            options={[
              { id: 'lastRunBy', label: 'Run By', orderable: true },
              { id: 'dateRun', label: 'Run Date', orderable: true },
              { id: 'reportStatus', label: 'Report Status', orderable: true, align: 'center' },
              { id: 'actions', label: 'Actions', align: 'center' },
            ]}
          />
          <tbody className="table-body">
            {reportHistory?.loading && displayedItems.length === 0 && (
              <tr>
                <td colSpan="6" className="history-loading"><div><LoadingAnimation size="small" variant="secondary" fullscreen={false} /> Loading...</div></td>
              </tr>
            )}
            {!reportHistory?.error && displayedItems.map((run) => (
              <tr
                key={run.id}
                className={`reports-row ${highlightedRunId === run.id ? 'highlight-row' : ''}`}
              >
                <td>{run.createdBy || '-'}</td>
                <td>{run.createdAt ? moment(run.createdAt).local().format('MMM DD, YYYY h:mm:ss a') : '-'}</td>
                <td className="report-status-chip">
                  <StatusChip
                    size="small"
                    type="report"
                    status={run.status}
                    iconLeft={run.status === 'Running' || run.status === 'Pending' ? <LoadingIcon /> : null}
                  /></td>
                <td className={`report-actions ${downloadingRunIds.includes(run.id) ? 'spin' : ''}`}>
                  <ButtonIcon
                    icon={downloadingRunIds.includes(run.id) ? <LoadingIcon className="spin" /> : <Download />}
                    disabled={!run.generatedFileUrl || downloadingRunIds.includes(run.id)}
                    onClick={() => {
                      handleDownload(run.generatedFileUrl, data.name, run.id)
                    }}
                  />
                </td>
              </tr>
            ))}
            {reportHistory?.data?.runs?.length === 0 && !reportHistory?.error && !reportHistory?.loading && (
              <tr>
                <td colSpan="6" className="error-message">No Report History</td>
              </tr>
            )}
            {reportHistory?.error && !reportHistory?.loading && (
              <tr>
                <td colSpan="6" className="error-message"><ExclamationCircle />Error fetching report data</td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
      {deleteReportModal && (
        <DeleteReportModal
          reportData={data}
          showDeleteReportModal={showDeleteReportModal}
        />
      )}
      {isOpen && (!reportHistory?.loading || displayedItems.length > 0) && !reportHistory?.error && totalItems > 3 && (
        <Pagination
          totalItems={totalItems}
          pageSizes={[3, 10, 50]}
          hideBelow={3}
          currentPage={currentPage}
          pageSize={pageSize}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          }}
        />
      )}
    </Card>
  )
}

export default ReportCard;