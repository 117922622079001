import { createSelector } from 'reselect';
import { formatSchemaObjKey, formatSchemaObjName } from 'app/pages/Merchants/MerchantDetails/TemplatesView/utils';

const merchantSelector = state => state.merchant;

export const merchantsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchants.data);
export const merchantsLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchants.loading);
export const merchantsErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchants.error);

export const merchantDetailsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchant.data);
export const merchantDetailsLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchant.loading);
export const merchantDetailsErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchant.error);

export const merchantTemplatesSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplates?.data);
export const merchantTemplatesLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplates.loading);
export const merchantTemplatesErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplates.error);

export const templateSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplate.data);
export const templateLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplate.loading);
export const templateErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplate.error);

export const fileSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplate?.renderData?.fileInfo);

export const configurationSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantConfiguration?.data);
export const configurationLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantConfiguration?.loading);

export const searchMerchantsSelector = createSelector([merchantSelector], merchantStore => merchantStore.searchMerchants?.data);
export const searchMerchantsLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.searchMerchants?.loading);
export const searchMerchantsErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.searchMerchants?.error);

export const searchViewAsMerchantSelector = createSelector([merchantSelector], merchantStore => merchantStore.searchViewAsMerchants?.data);
export const searchViewAsMerchantLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.searchViewAsMerchants?.loading);
export const searchViewAsMerchantErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.searchViewAsMerchants?.error);

export const bulkGetMerchantsSelector = createSelector([merchantSelector], merchantStore => merchantStore.bulkMerchants?.data);
export const bulkGetMerchantsLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.bulkMerchants?.loading);
export const bulkGetMerchantsErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.bulkMerchants?.error);

export const schemaSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantSchema?.data);

//
// Config and Schema constants...
//
// Those string in constants are defined in schema and configuration.
// If schema or configuration changes, those constants should be updated.
// We need them here hardcoded in order to properly render UI.
//
export const SchemaTypes = {
    BOOLEAN: 'boolean',
    OBJECT: 'object',
    STRING: 'string',
    INTEGER: 'integer',
    INPUT: 'input'
}
export const ConfigTypes = {
    // this is a special value for Basic settings section, it's not in schema...
    BASIC_SETTINGS: 'BasicSettings',
    CUSTOM_RETURN_ADDRESS: 'CustomReturnAddress',
    ADDRESS: 'Address',
    RETURN_ADDRESS_TYPE: "ReturnAddressType",
    RETURN_ADDRESS_TYPE_TEXT: {
        productionFacilityAddress: "Use the production facilities address",
        myRegisteredAddress: "Use my register Address",
        inputCustomAddress: "Input Custom Return Address"
    },
    BRANDING_SETTINGS: 'BrandingSettings',
    PACKING_SLIP_TEMPLATE_ID: 'PackingSlipTemplateId',
    PACKING_SLIP: 'packing-slip',
    // this is a special value for dropdown, to create new template, it's not in schema...
    CREATE_TEMPLATE: 'createTemplate'
}

//
// Template settings selectors and logic...
//
const customReturnAddress = (settings, key, item) => {
    const customAddressSettings = {
        title: formatSchemaObjName(key),
        key,
        settings: Object.keys(item.properties).map(subKey => {
            // create boolean settings for checkboxes...

            if (subKey === ConfigTypes.RETURN_ADDRESS_TYPE) {
                const options = item?.properties[ConfigTypes.RETURN_ADDRESS_TYPE]?.enum.map(o => ({
                    value: o,
                    label: ConfigTypes.RETURN_ADDRESS_TYPE_TEXT[o] ?? o
                })) || [];

                return {
                    ...item.properties[subKey],
                    key: subKey,
                    options
                }
            }

            if (subKey === ConfigTypes.ADDRESS) {
                const addressHolder = item?.properties[subKey]?.properties
                if (addressHolder) {
                    return Object.keys(addressHolder).map(addressKey => {
                        return {
                            description: formatSchemaObjKey(addressKey),
                            key: addressKey
                        }
                    })?.sort((a, b) => a.description.localeCompare(b.description));
                }
            }
        })
    };

    // flatten settings inside "CustomReturnAddress"...
    customAddressSettings.settings = customAddressSettings.settings.flat().filter(Boolean);

    settings.push(customAddressSettings);
}

const brandingSettings = (settings, key, item, templates) => {
    settings.push({
        title: formatSchemaObjName(key),
        key,
        settings: Object.keys(item.properties).map(subKey => {
            // support only boolean and "PackingSlipTemplateId" for now...
            if (item.properties[subKey].type === SchemaTypes.BOOLEAN) {
                return {
                    ...item.properties[subKey],
                    key: subKey
                }
            }

            if (subKey === ConfigTypes.PACKING_SLIP_TEMPLATE_ID) {
                const createTemplateOpt = {
                    value: ConfigTypes.CREATE_TEMPLATE,
                    label: `+ ${formatSchemaObjName(ConfigTypes.CREATE_TEMPLATE)}`
                }

                const options = templates?.[ConfigTypes.PACKING_SLIP]?.map(template => ({
                    value: template.id,
                    label: template.name
                })) || [];

                options.push(createTemplateOpt);

                return {
                    ...item.properties[subKey],
                    key: subKey,
                    options
                }
            }

        }).filter(Boolean)
    });
}

export const settingsSelector = createSelector([schemaSelector, merchantTemplatesSelector], (schemaData, templates) => {
    const basicSettings = [];
    const settings = [];

    const schema = schemaData?.jsonSchema?.properties;
    if (!schema) {
        return [];
    }

    Object.keys(schema).forEach(key => {
        const item = schema[key];

        // those are Basic Settings, they are in root and primitives...
        if (item.type === SchemaTypes.BOOLEAN || item.type === SchemaTypes.INTEGER) {
            basicSettings.push({
                ...item,
                key
            });
        }

        // all other object types are nested settings...
        // data is not consistent with UI design requirements, so we have to handle it here...
        if (item.type === SchemaTypes.OBJECT) {

            // here we will build settings for "CustomReturnAddress"...
            if (key === ConfigTypes.CUSTOM_RETURN_ADDRESS) {
                customReturnAddress(settings, key, item);
            }

            // but we know that we will support "Branding Settings"
            if (key === ConfigTypes.BRANDING_SETTINGS) {
                brandingSettings(settings, key, item, templates);
            }
        }
    });

    // this one should be hardcoded, because this section doesn't exist in Configuration Schema...
    if (basicSettings.length) {
        settings.push({
            title: formatSchemaObjName(ConfigTypes.BASIC_SETTINGS),
            key: ConfigTypes.BASIC_SETTINGS,
            settings: basicSettings
        });
    }

    // sort settings by title...
    settings.sort((a, b) => a.title.localeCompare(b.title));

    return settings;
});

export const schemaLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantSchema?.loading);

export const merchantSelectedPackingSlipSelector = createSelector([configurationSelector], config => config?.data?.BrandingSettings?.PackingSlipTemplateId);
export const merchantSelectedReturnAddressType = createSelector([configurationSelector], config => config?.data?.CustomReturnAddress?.ReturnAddressType)

// this is a sample data for rendering some of templates...
// this data has to be provided in request payload, when rendering any template...
// https://gooten.atlassian.net/browse/OMG-107
export const sampleDataTemplateSelector = () => {
    return {
        "order": {
            "id": "664d04077826075558ab4244",
            "shippingAddress": {
                "firstName": "John",
                "lastName": "Doe",
                "line1": "123 Main St",
                "line2": "Apt 4B",
                "line3": null,
                "city": "Anytown",
                "state": "CA",
                "zip": "90210",
                "countryCode": "US",
                "phone": "555-1234",
                "email": "john.doe@example.com",
                "location": {
                    "longitude": -118.416253,
                    "latitude": 34.103131
                }
            },
            "billingAddress": {
                "firstName": "Jane",
                "lastName": "Smith",
                "line1": "456 Oak St",
                "line2": "",
                "line3": null,
                "city": "Othertown",
                "state": "NY",
                "zip": "10001",
                "countryCode": "US",
                "phone": "555-5678",
                "email": "jane.smith@example.com",
                "location": null
            },
            "externalId": "ORDER123456",
            "safeId": "UniqueJaimeTest",
            "languageCode": "EN",
            "customerTotal": {
                "currency": "USD",
                "amount": 200.5
            },
            "status": "fulfillmenterror",
            "merchantId": "46bae16f-3ccd-4afa-90d2-706551acbed7",
            "merchantName": "Sales channel 1",
            "customerShippingMethod": 1,
            "isTest": true,
            "createdAt": "2024-05-21T20:28:55.518Z",
            "updatedAt": "2024-05-21T20:28:59.274Z",
            "cancelledAt": null,
            "items": [
                {
                    "id": "664d04077826075558ab4245",
                    "sku": "Scrunchie-Jersey",
                    "quantity": 2,
                    "status": "open",
                    "createdAt": "2024-05-21T20:28:55.518Z",
                    "updatedAt": "2024-05-21T20:28:56.761Z",
                    "processedAt": null,
                    "cancelledAt": null,
                    "images": [
                        {
                            "url": "https://www.pixelstalk.net/wp-content/uploads/2016/07/Free-Amazing-Background-Images-Nature.jpg",
                            "manipCommand": "",
                            "thumbnailUrl": "https://www.pixelstalk.net/wp-content/uploads/2016/07/Free-Amazing-Background-Images-Nature.jpg",
                            "thumbnailFileId": null,
                            "area": "Default",
                            "printMethod": "DTG",
                            "ourUrl": "https://gtndevfilessa.blob.core.windows.net/private/order-image/664d04077826075558ab4244/664d04077826075558ab4245/3f4a8cdd-5775-4253-8cf5-c33496292119?sv\u003d2023-01-03\u0026st\u003d2024-05-21T20%3A28%3A56Z\u0026se\u003d2024-05-21T21%3A28%3A56Z\u0026sr\u003db\u0026sp\u003dr\u0026sig\u003dPwNi%2BPu2j7SaQdPg9GrB5iRS%2BuBzRRLFpBsWAAr7Zws%3D",
                            "ourFileId": "3f4a8cdd-5775-4253-8cf5-c33496292119",
                            "generatedImageUrl": null,
                            "generatedFileId": null,
                            "vendorFileId": null
                        }
                    ],
                    "options": [
                        {
                            "name": "Size",
                            "value": "Medium"
                        },
                        {
                            "name": "Color",
                            "value": "White"
                        }
                    ],
                    "attributes": null,
                    "customerSubtotal": {
                        "currency": "USD",
                        "amount": 40.0
                    },
                    "customerPrice": {
                        "currency": "USD",
                        "amount": 20.0
                    },
                    "customerShippingPrice": {
                        "currency": "USD",
                        "amount": 5.0
                    },
                    "merchantPrice": {
                        "currency": "USD",
                        "amount": 10.9000
                    },
                    "merchantShippingPrice": null,
                    "clientAttributes": {
                        "occasion": "Birthday gift",
                        "urgency": "high"
                    },
                    "meta": {
                        "designId": "D123456",
                        "artist": "ArtistName"
                    },
                    "isSample": true,
                    "externalId": "PROD7890123",
                    "skipImgManip": true
                }
            ],
            "meta": {
                "customerNote": "Please deliver between 9 AM and 5 PM.",
                "giftWrap": "true",
                "promocode": "SUMMER2024",
                "packing_slip": "ec505671-d9b2-44ba-917a-8f5e937443e4"
            }
        },
        "merchant": {
            "id": "46bae16f-3ccd-4afa-90d2-706551acbed7",
            "shortId": "01310001",
            "children": [],
            "parent": null,
            "legalBusinessName": "Sales Channel 1 ",
            "email": "APItest@mail.com",
            "name": "Sales channel 1",
            "url": null,
            "address1": "Main st. 42",
            "address2": "",
            "city": "New York",
            "state": "New York",
            "country": "US",
            "zipCode": "10001",
            "phoneNumber": null,
            "enabled": true,
            "isParent": false,
            "skipImgManip": null,
            "customShipping": null,
            "isMerchantVasFlexible": true
        },
        "shipment": {
            "id": "ef0978f4-a0b6-44cf-9312-0af835d02812",
            "merchantId": "46bae16f-3ccd-4afa-90d2-706551acbed7",
            "merchantName": null,
            "vendorId": "aeec84ef-98bf-470d-9df6-99526e0a13b4",
            "facilityId": "F01250005",
            "vendorName": null,
            "orderId": "664d04077826075558ab4244",
            "shortId": "S07900324",
            "orderExternalId": null,
            "safeId": null,
            "customerId": null,
            "customerShippingAddressId": 0,
            "merchantPaymentId": null,
            "shippingCountryCodeISO": 0,
            "shippingCountryCode": null,
            "shipmentStatus": 1,
            "currencyCode": "USD",
            "languageCodeISO": 0,
            "languageCode": null,
            "transactionalCostModel": null,
            "createdDate": "2024-05-21T20:28:56.938Z",
            "orderCreatedDate": "0001-01-01T00:00:00Z",
            "lastModifiedDate": "0001-01-01T00:00:00",
            "merchantOrderTotal": 21.8,
            "merchantPackageTotal": 21.8,
            "merchantTaxTotal": 0.0,
            "merchantShippingTotal": 0.0,
            "packageTotal": 18.0,
            "taxTotal": 0.0,
            "shippingTotal": 0.0,
            "total": 18.0,
            "costTotal": 18.0,
            "costTaxTotal": 0.0,
            "costShippingTotal": 0.0,
            "costShippingTaxTotal": 0.0,
            "packages": [
                {
                    "id": "b6d9e60c-8afc-4679-a7a2-f6f93274d454",
                    "shortId": "P08900273",
                    "shipmentId": "ef0978f4-a0b6-44cf-9312-0af835d02812",
                    "carrierName": "USPS",
                    "carrierMethodName": "Priority",
                    "shippedDate": "0001-01-01T00:00:00Z",
                    "fulfilledDate": "0001-01-01T00:00:00Z",
                    "carrierTrackingNumber": "9405500110380043443091",
                    "carrierLink": "https://track.easypost.com/djE6dHJrXzg1ZWQ2NmQ5MWIwNDQxMTQ4NTI4YWQxMWZlODNmZThh",
                    "carrierAccount": true,
                    "orderItemIDs": [
                        "664d04077826075558ab4245"
                    ]
                }
            ],
            "orderItemIDs": [
                "664d04077826075558ab4245"
            ],
            "orderItems": [
                {
                    "id": "664d04077826075558ab4245",
                    "sku": "Scrunchie-Jersey",
                    "vendorSku": "SC0001U",
                    "variantId": "144029",
                    "vendorProductId": "aa7a8f97-486c-4920-a6f8-493ee4dff7ba",
                    "quantity": 2,
                    "merchantPrice": {
                        "currency": "USD",
                        "amount": 10.9,
                        "tax": 0.0
                    },
                    "price": {
                        "currency": "USD",
                        "amount": 9.0,
                        "tax": 0.0
                    }
                }
            ]
        },
        "vendor": {
            "vendorId": "aeec84ef-98bf-470d-9df6-99526e0a13b4",
            "name": "ORTest Data Vendor 3",
            "facilities": [
                {
                    "id": "F01250005",
                    "name": "Miami",
                    "address": {
                        "line1": "16085 NW 52nd Ave",
                        "city": "Miami",
                        "state": "FL",
                        "zip": "33014",
                        "countryCode": "US",
                        "location": null,
                        "line2": ""
                    },
                    "shippingMethods": []
                }
            ],
            "address": {
                "line1": "16085 NW 52nd Ave",
                "city": "Florida",
                "state": "Miami",
                "zip": "33014",
                "countryCode": "US",
                "location": null,
                "line2": ""
            }
        }
    }
}