import { createSelector } from 'reselect';

const insightSelector = state => state.insight;

export const queriesSelector = createSelector([insightSelector], insightStore => insightStore.queries.data);
export const queriesLoadingSelector = createSelector([insightSelector], insightStore => insightStore.queries.loading);
export const queriesErrorsSelector = createSelector([insightSelector], insightStore => insightStore.queries.error);

export const queryDataSelector = createSelector([insightSelector], insightStore => insightStore.queryData);

export const reportsSelector = createSelector([insightSelector], insightStore => insightStore.reports.data);
export const reportsLoadingSelector = createSelector([insightSelector], insightStore => insightStore.reports.loading);
export const reportsErrorsSelector = createSelector([insightSelector], insightStore => insightStore.reports.error);

export const reportRunHistoryByIdSelector = (reportId) => 
  createSelector(
    [insightSelector],
    insightStore => insightStore.reportRunHistory.data[reportId] || { loading: false, error: null, data: null }
  );

export const availableReportColumnsSelector = createSelector([insightSelector], insightStore => insightStore.availableReportColumns.data);
export const availableReportColumnsLoadingSelector = createSelector([insightSelector], insightStore => insightStore.availableReportColumns.loading);
export const availableReportColumnsErrorsSelector = createSelector([insightSelector], insightStore => insightStore.availableReportColumns.error);

export const createReportSelector = createSelector([insightSelector], insightStore => insightStore.createReport.data);
export const createReportLoadingSelector = createSelector([insightSelector], insightStore => insightStore.createReport.loading);
export const createReportErrorsSelector = createSelector([insightSelector], insightStore => insightStore.createReport.error);

export const runReportSelector = createSelector([insightSelector], insightStore => insightStore.runReport.data);
export const runReportLoadingSelector = createSelector([insightSelector], insightStore => insightStore.runReport.loading);
export const runReportErrorsSelector = createSelector([insightSelector], insightStore => insightStore.runReport.error);

export const reportPreviewDataSelector = createSelector([insightSelector], insightStore => insightStore.reportPreviewData.data);
export const reportPreviewDataLoadingSelector = createSelector([insightSelector], insightStore => insightStore.reportPreviewData.loading);
export const reportPreviewDataErrorsSelector = createSelector([insightSelector], insightStore => insightStore.reportPreviewData.error);