const getDateRange = (type, value, value2) => {
  const startDate = new Date(value);
  let endDate;

  switch (type.toLowerCase()) {
    case 'on':
      // Set time to start of day (00:00:00) for start date
      startDate.setUTCHours(0, 0, 0, 0);
      // Set time to end of day (23:59:59.999) for the same date
      endDate = new Date(value);
      endDate.setUTCHours(23, 59, 59, 999);
      break;

    case 'before':
      // Set end date to the day before at 23:59:59.999
      endDate = new Date(value);
      endDate.setUTCHours(23, 59, 59, 999);
      endDate.setUTCDate(endDate.getUTCDate() - 1);
      // Set start date to a very early date
      startDate.setUTCFullYear(1970);
      startDate.setUTCHours(0, 0, 0, 0);
      break;

    case 'after':
      // Set start date to the day after at 00:00:00
      startDate.setUTCDate(startDate.getUTCDate() + 1);
      startDate.setUTCHours(0, 0, 0, 0);
      // Set end date to a far future date
      endDate = new Date();
      endDate.setUTCFullYear(2099);
      endDate.setUTCHours(23, 59, 59, 999);
      break;

    case 'onorbefore':
      // Set start date to a very early date
      startDate.setUTCFullYear(1970);
      startDate.setUTCHours(0, 0, 0, 0);
      // Set end date to the specified date at 23:59:59.999
      endDate = new Date(value);
      endDate.setUTCHours(23, 59, 59, 999);
      break;

    case 'onorafter':
      // Set start date to the specified date at 00:00:00
      startDate.setUTCHours(0, 0, 0, 0);
      // Set end date to a far future date
      endDate = new Date();
      endDate.setUTCFullYear(2099);
      endDate.setUTCHours(23, 59, 59, 999);
      break;

    case 'between':
      // Set start date to beginning of first date
      startDate.setUTCHours(0, 0, 0, 0);
      // Set end date to end of second date
      endDate = new Date(value2);
      endDate.setUTCHours(23, 59, 59, 999);
      break;

    default:
      throw new Error(`Unsupported date filter type: ${type}`);
  }

  return [startDate.toISOString(), endDate.toISOString()];
};

export const buildFiltersObject = (activeFilters) => {
  if (!activeFilters || Object.keys(activeFilters).length === 0) {
    return null;
  }

  const filters = Object.entries(activeFilters).map(([column, filterData]) => {
    if (filterData.columnType === 'date') {
      const [startDate, endDate] = getDateRange(filterData.type, filterData.value, filterData.value2);
      return {
        column,
        operator: 'Between',
        value: [startDate, endDate]
      };
    }

    const operator = filterData.type.charAt(0).toUpperCase() + filterData.type.slice(1);
    if (operator === 'Between' && filterData.value2) {
      return {
        column,
        operator,
        value: [filterData.value, filterData.value2]
      };
    }

    return {
      column,
      operator: filterData.type.charAt(0).toUpperCase() + filterData.type.slice(1),
      value: filterData.value,
    };
  });

  return {
    operator: 'And',
    filters
  };
};
