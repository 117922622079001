import React from 'react';
import { Card, MessageBar } from 'app/components';
import StatusCard from '../OrdersByStatus/StatusCard';
import { Pass, BoxSeam, Image, GeoAlt, PauseFill } from 'react-bootstrap-icons';
import { ReactComponent as FulfillmentError } from 'app/assets/images/icons/fulfillment-error.svg';
import Skeleton from 'react-loading-skeleton';
import '../OrdersByStatus/index.scss';

const ShipmentCountByStatus = ({ queryData = {} }) => {
  const {
    data,
    loading,
    error,
  } = queryData;

  const getIcon = (status) => {
    switch (status) {
      case 'Open':
        return { icon: <Pass />, color: 'green' };
      case 'Canceled':
        return { icon: <Pass />, color: 'red' };
      case 'ReadyForFulfillment':
        return { icon: <Pass />, color: 'green' };
      case 'Fulfilled':
        return { icon: <Pass />, color: 'green' };
      case 'InProduction':
        return { icon: <BoxSeam />, color: 'green' };
      case 'ImageError':
        return { icon: <Image />, color: 'red' };
      case 'AddressIssue':
        return { icon: <GeoAlt />, color: 'red' };
      case 'FulfillmentError':
        return { icon: <FulfillmentError />, color: 'red' };
      case 'Hold':
        return { icon: <PauseFill />, color: 'yellow' };
      default:
        return { icon: <Pass />, color: 'green' };
    }
  }

  return (
    <Card className="status-cards-container">
      <div className="status-cards-header">
        Shipments by Status
      </div>
      {data && data.length > 0 && (
        <div className="status-cards">
          {data.map((status, index) => {
            const icon = getIcon(status.status_name);
            return (
              <StatusCard
                key={index}
                label={status.status_name}
                icon={icon.icon}
                iconColor={icon.color}
                value={status.shipments_count}
              />
            )
          })}
        </div>
      )}
      {!loading && !error && data && data.length === 0 && (
        <MessageBar color="yellow">
          No data available
        </MessageBar>
      )}
      {error && (
        <MessageBar color="yellow">
          An error occurred while fetching data
        </MessageBar>
      )}
      {loading && (
        <Skeleton height={100} count={2} borderRadius={15} />
      )}
    </Card>
  )
}

export default ShipmentCountByStatus;