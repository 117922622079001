import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class InsightServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.InsightMS.Url
    });

    SetupInterceptors(this.api);
  }

  getAllQueries = async () => {
    try {
      const response = await this.api.get(`/v1/query`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching queries from API');
    }
  };

  runQueryById = async (queryId, runAs, dateRange, merchantIds, vendorIds) => {
    try {
      let queryString = "";

      const addParam = (key, value) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `&${key}=${encodeURIComponent(value)}`;
        }
      };

      if (dateRange) {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - dateRange);
        addParam('startDate', startDate.toISOString()?.split('T')[0]);
      }

      addParam('runAs', runAs);

      if (merchantIds) {
        merchantIds.forEach(m => {
          addParam('merchantIds', m);
        });
      }

      if (vendorIds) {
        vendorIds.forEach(v => {
          addParam('vendorIds', v);
        });
      }

      // runAs not needed for now...
      const response = await this.api.get(`/v1/query/${queryId}/run?${queryString}`);
      return response.data;
    } catch (error) {
      console.log('Error running query', error);
      throw new Error('Error running query');
    }
  };

  getAllReports = async ({ page, pageSize } = {}) => {
    try {
      const queryParams = new URLSearchParams();
      if (page) queryParams.append('page', page);
      if (pageSize) queryParams.append('pageSize', pageSize);
      
      const response = await this.api.get(`/v1/reports${queryParams.toString() ? `?${queryParams.toString()}` : ''}`);
      return response.data;
    } catch (error) {
      console.log('Error fetching reports', error);
      throw new Error('Error fetching reports');
    }
  };

  getSpecificReport = async (reportId) => {
    try {
      const response = await this.api.get(`/v1/reports/${reportId}`);
      return { reports: [response.data], total: 1 };
    } catch (error) {
      console.log('Error fetching specific report', error);
      throw new Error('Error fetching specific report from API');
    }
  };

  getReportRunHistory = async (reportId, page, pageSize) => {
    try {
      const queryParams = new URLSearchParams();
      if (page) queryParams.append('page', page);
      if (pageSize) queryParams.append('pageSize', pageSize);
      
      const response = await this.api.get(`/v1/reports/${reportId}/runs${queryParams.toString() ? `?${queryParams.toString()}` : ''}`);
      return response.data;
    } catch (error) {
      console.log('Error fetching report run history', error);
      throw new Error('Error fetching report run history');
    }
  };

  getAvailableReportColumns = async () => {
    try {
      const response = await this.api.get(`/v1/reports/columns`);
      return response.data;
    } catch (error) {
      console.log('Error fetching report columns', error);
      throw new Error('Error fetching report columns');
    }
  };

  createReport = async (data) => {
    try {
      const response = await this.api.post(`/v1/reports`, data);
      return response.data;
    } catch (error) {
      console.log('Error creating report', error);
      throw new Error('Error creating report');
    }
  };

  runReport = async (reportId, runAs, merchantIds, vendorIds) => {
    try {
      const response = await this.api.post(`/v1/reports/${reportId}/run`, { runAs, merchantIds, vendorIds });
      return response.data;
    } catch (error) {
      console.log('Error running report', error);
      throw new Error('Error running report');
    }
  };

  getReportRunDetails = async (reportId, runId) => {
    try {
      const response = await this.api.get(`/v1/reports/${reportId}/runs/${runId}`);
      return response.data;
    } catch (error) {
      console.log('Error fetching report run details', error);
      throw new Error('Error fetching report run details');
    }
  };

  deleteReport = async (reportId) => {
    try {
      const response = await this.api.delete(`/v1/reports/${reportId}`);
      return response.data;
    } catch (error) {
      console.log('Error deleting report', error);
      throw new Error('Error deleting report');
    }
  };

  getReportPreviewData = async (data) => {
    try {
      const response = await this.api.post(`/v1/reports/preview`, data);
      return response.data;
    } catch (error) {
      console.log('Error fetching report preview data', error);
      throw new Error('Error fetching report preview data');
    }
  };
}

export default new InsightServices();