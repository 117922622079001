import React from 'react';
import OrderStatusDetails from './orderStatusDetails';
import OrderItemStatusDetails from './orderItemStatusDetails';
import ShipmentStatusDetails from './shipmentStatusDetails';
import DefaultStatusDetails from './defaultStatusDetails';
import UserStatusDetails from './userStatusDetails';
import WebhooksStatusDetails from './webhooksStatusDetails';
import ProductStatusDetails from './productStatusDetails';
import InventoryStatusDetails from './inventoryStatusDetails';
import ReportStatusDetails from './reportStatusDetails';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const StatusChip = ({
  size = 'small', 
  type = 'default', 
  status,
  iconLeft = null, 
  iconRight = null,
  onClick = null, 
  darkMode = false,
}) => {
  let statusDetails;

  switch (type) {
    case 'order':
      statusDetails = OrderStatusDetails;
      break;
    case 'orderItem':
      statusDetails = OrderItemStatusDetails;
      break;
    case 'shipment':
      statusDetails = ShipmentStatusDetails;
      break;
    case 'user':
      statusDetails = UserStatusDetails;
      break;
    case 'webhooks':
      statusDetails = WebhooksStatusDetails;
      break;
    case 'product':
      statusDetails = ProductStatusDetails;
      break;
    case 'inventory': 
      statusDetails = InventoryStatusDetails; 
      break;
    case 'report':
      statusDetails = ReportStatusDetails;
      break;
    default:
      statusDetails = DefaultStatusDetails;
      break;
  }

  const lowerCaseStatus = String(status).toLowerCase() || '';

  const details = status ? statusDetails[lowerCaseStatus] || {} : {};
  const className = classNames('status-chip', {
    [`size-${size}`]: size,
    'clickable': onClick,
    [`status-${details.type}`]: details.type,
    'dark-mode': darkMode
  });

  return (
    <div
      className={className}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      data-theme={darkMode ? 'dark' : 'light'}
    >
      {iconLeft && <span className="status-icon-left">{iconLeft}</span>}
      {details.displayName || status}
      {iconRight && <span className="status-icon-right">{iconRight}</span>}
    </div>
  );
};

StatusChip.propTypes = {
  size: PropTypes.oneOf(['slim', 'small', 'medium']),
  type: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  onClick: PropTypes.func,
};

export default StatusChip;
